import Vue from "vue";
import { mobilecheck } from "./map";
import { bus } from "./bus";
import images from "images/winners/*.JPG";
import imagesLower from "images/winners/*.jpg";

const startIdx = Math.floor(
  Math.random() *
    Math.floor(
      Object.keys(images).length + Object.keys(imagesLower).length - 0.01
    )
);
const imageList = { ...images, ...imagesLower };

export const photos = Vue.component("inline-images", {
  data: function() {
    return {
      currentPhotoIdx: startIdx,
      currentPhoto: imageList[Object.keys(imageList)[startIdx]],
      photos: imageList
    };
  },
  methods: {
    preload: function(idx) {
      const prevIndex =
        idx - 1 < 0 ? Object.keys(this.photos).length - 1 : idx - 1;
      const nextIndex = (idx + 1) % Object.keys(this.photos).length;

      const img = new Image();
      img.src = this.photos[Object.keys(this.photos)[prevIndex]];
      img.src = this.photos[Object.keys(this.photos)[nextIndex]];
    },
    next: function() {
      this.currentPhotoIdx =
        (this.currentPhotoIdx + 1) % Object.keys(this.photos).length;

      this.preload(this.currentPhotoIdx);
      this.currentPhoto = this.photos[
        Object.keys(this.photos)[this.currentPhotoIdx]
      ];
    },
    prev: function() {
      this.currentPhotoIdx =
        this.currentPhotoIdx - 1 < 0
          ? Object.keys(this.photos).length - 1
          : this.currentPhotoIdx - 1;
      this.preload(this.currentPhotoIdx);
      this.currentPhoto = this.photos[
        Object.keys(this.photos)[this.currentPhotoIdx]
      ];
    },
    launchModal: function() {
      bus.$emit("launchModal", this.currentPhotoIdx);
    }
  },
  mounted: function() {
    this.preload(this.currentPhotoIdx);
    window.addEventListener("keyup", event => {
      if (event.keyCode === 37) {
        this.prev();
      }
      if (event.keyCode === 39) {
        this.next();
      }
    });
  },
  template: `<div>
        <div
          id="image-inline"
        >
            <p v-on:click="launchModal" class="image">
              <img v-bind:src="currentPhoto" alt="" />
            </p>
            <div class="inline-carousel-container">
              <div class="carousel-buttons">
                <span class="icon is-large" v-on:click="prev">
                  <i
                    class="oi"
                    data-glyph="arrow-circle-left"
                    aria-hidden="true"
                /></span>
                <span class="icon is-large" v-on:click="next">
                  <i
                    class="oi"
                    data-glyph="arrow-circle-right"
                    aria-hidden="true"
                /></span>
              </div>
            </div>
        </div>
  </div>`
});
