import Vue from "vue";
import todoImages from "images/todo/*.jpg";

export const cards = Vue.component("todo-card", {
  data: function() {
    return {
      count: 0
    };
  },
  methods: {
    resolveImg: function(img) {
      return todoImages[img];
    }
  },
  props: {
    title: String,
    links: Array,
    img: String
  },
  template: `
  <div class="card">
  <div v-if="img" class="card-image">
    <figure class="image">
      <img v-bind:src="resolveImg(img)" alt="Placeholder image">
    </figure>
  </div>
  <div class="card-content">
    <div class="media">
      <div class="media-content">
        <p class="title is-4">{{ title }}</p>
      </div>
    </div>

    <div class="content">
    <slot></slot>
    </div>
    <footer v-if="links" class="card-footer">
      <a v-for="link in links" class="card-footer-item" v-bind:href="link" target="_blank">
        Visit website
      </a>
    </footer>
  </div>
</div>`
});
