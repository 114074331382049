import { scrollTo } from "./navbar";

export var locations = [
  {
    content: "",
    directionsLink: "https://goo.gl/maps/WRVb1FUBkjPctU9fA",
    location: { lat: 44.886166, lng: -85.528276 },
    title: "Jolly Pumpkin"
  },
  /*
  {
    content: "",
    directionsLink: "https://goo.gl/maps/7Mhj6SXtULyk7CPY9",
    location: { lat: 44.764997, lng: -85.601815 },
    title: "Island View Cottages"
  },
  */
  {
    content: "",
    directionsLink: "https://goo.gl/maps/YMehXffip8k9Zoo28",
    location: { lat: 44.895883, lng: -86.057602 },
    title: "Sleeping Bear Dunes"
  },
  {
    content: "",
    directionsLink: "https://goo.gl/maps/nYGMRZkhbo7nr6858",
    location: { lat: 44.736937, lng: -85.579811 },
    title: "Cherry Capital Airport"
  },
  {
    content: "",
    directionsLink: "https://goo.gl/maps/x3XPgd6qTdYjeadp9",
    location: { lat: 44.763624, lng: -85.613757 },
    title: "The Little Fleet"
  },
  {
    content: "July 25 4pm. Be there!",
    directionsLink: "https://goo.gl/maps/Ck9RRVARzgFTz1eFA",
    location: { lat: 44.886069, lng: -85.528669 },
    title: "Peninsula Room",
    zIndex: 100
  }
];

export function mobilecheck() {
  var check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
//
// Initialize and add the map
export let map;

export function initMap() {
  if (mobilecheck()) {
    var mapCenter = { lat: 44.834338, lng: -85.590013 };
  } else {
    var mapCenter = { lat: 44.834338, lng: -85.740013 };
  }

  map = new google.maps.Map(document.getElementById("map"), {
    zoom: 11,
    center: mapCenter,
    mapTypeControl: false
  });
  locations.forEach(l => {
    const contentString = `
      <div class="infoWindowContent">
        <h4>${l.title}</h4>
        <p>
        ${l.content}
        </p>
      <a href="${l.directionsLink}" target="_blank">Get Directions</a></div>`;
    const infowindow = new google.maps.InfoWindow({
      content: contentString
    });
    const marker = new google.maps.Marker({
      map: map,
      position: l.location,
      title: l.title,
      zIndex: l.zIndex
    });

    var mouseOutFunc = function() {
      infowindow.close(map, marker);
    };

    marker.addListener("mouseover", function() {
      infowindow.open(map, marker);
    });

    marker.addListener("mouseout", function() {
      mouseOutFunc();
    });
    marker.addListener("click", function() {
      mouseOutFunc = function() {};
      infowindow.open(map, marker);
      map.panTo(marker.getPosition());
    });
    google.maps.event.addListener(infowindow, "closeclick", function() {
      mouseOutFunc = function() {
        infowindow.close(map, marker);
      };
    });
    l.marker = marker;
    l.infoWindow = infowindow;
  });

  // TODO: Handle links more sensibly
  /*
  const airport = locations.filter(
    l => l.title === "Cherry Capital Airport"
  )[0];
  document.getElementById("tvclauncher").addEventListener("click", function() {
    scrollTo("map");
    airport.infoWindow.open(map, airport.marker);
    map.panTo(airport.marker.getPosition());
  });
  */

  const littleFleet = locations.filter(l => l.title === "The Little Fleet")[0];
  document.getElementById("sblauncher").addEventListener("click", function() {
    scrollTo("map");
    littleFleet.infoWindow.open(map, littleFleet.marker);
    map.panTo(littleFleet.marker.getPosition());
  });
}
