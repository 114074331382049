<template>
  <div id="main">
    <nav-and-modal> </nav-and-modal>
    <div id="welcome-welcome">
      <div id="us-img">
        <img src="images/pic-cropped.jpg" />
      </div>
      <div id="banner">
        <div class="banner-come">
          Celebrate with us!
        </div>
        <div class="banner-date">
          July 25, 2020
        </div>
        <div id="banner-location">The Peninsula Room, Traverse City, MI</div>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <p class="is-size-4 has-text-centered">
          We would be honored for you to join us in a celebration of love,
          partnership, and silly dance moves. Mark your calendar, pack your
          bags, and we'll see you in Northern Michigan!
        </p>
      </div>
    </section>
    <div id="travel-img" class="bgimg-1">
      <div class="caption">
        <span class="border">GETTING THERE</span>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="content has-text-centered">
          <h1>Shuttle</h1>
          <p>
            We'll be providing a shuttle to and from our wedding, for anyone who
            chooses to meet it at one of the two pick-up points. It will pick-up before
            the wedding (exact time TBD) and drop-off (a number of times throughout the
            night, exact times TBD) at the Comfort Inn and Island View Cottages.
          </p>

          <div class="level">
            <div class="level-item has-text-centered">
              <p>
                <b>Comfort Inn</b> <br />
                460 Munson Ave. US Hwy 31 <br />
                Traverse City, MI 49686
              </p>
            </div>

            <div class="level-item has-text-centered">
              <p>
                <b>Island View Cottages</b><br />
                853 E. Front St.<br />
                Traverse City, MI 49686
              </p>
            </div>
          </div>

          <h1>Around Town</h1>
          <p>
            For getting to Traverse City and mozying around town during the rest
            of your stay, there are a number of options available.
          </p>

          <table class="table is-hoverable is-fullwidth">
            <tbody>
              <tr>
                <td class="has-text-centered">
                  <b>Cherry Capital Airport in Traverse City</b>
                </td>
                <td class="has-text-centered">
                  This is the closest airport to fly into for our weekend
                  celebration, just a ten minute drive to downtown Traverse
                  City.
                </td>
              </tr>

              <tr>
                <td class="has-text-centered">
                  <b>Other Airports</b>
                </td>
                <td class="has-text-centered">
                  Gerald R. Ford International Airport in Grand Rapids is the
                  next closest at (2 ½ hr drive) and will likely be cheapest.
                  Pellston Regional Airport is also about 2 ½ hour drive.
                </td>
              </tr>

              <tr>
                <td class="has-text-centered"><b>Lyft / Uber / rentals</b></td>
                <td class="has-text-centered">
                  If you can bring your own car or rental, you will have more
                  ability to explore the surrounding area. There will be parking
                  at the reception. However, a car is not absolutely necessary
                  if you fly into Traverse City. If you stay in near downtown
                  there are Lyft / Uber options but keep the cab number below
                  handy as availability can sometimes be limited.
                </td>
              </tr>

              <tr>
                <td class="has-text-centered"><b>Cherry Capital Cab</b></td>
                <td class="has-text-centered">
                  Traverse City's original taxi service is available 24/7. They
                  also have larger vans for group transportation from the
                  airport or hotel.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <div id="lodging-img" class="bgimg-4">
      <div class="caption">
        <span class="border">LODGING</span>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="content has-text-centered">
          <span class="has-text-centered"><h1>Book early!</h1></span>
          <p>
            Unfortunately, we aren’t the only ones who love this area. Traverse
            City in summertime is popular. Please, please look into booking your
            flights and lodging ASAP. We will be staying downtown Traverse City
            near Front Street. The reception is a 15 minute drive from downtown
            Traverse City (on Old Mission Peninsula).
          </p>

          <h1>Room Block</h1>
          <p>
            We've blocked rooms at the <b>Comfort Inn</b> for two nights to serve as a
            pick-up and drop-off location. The block is for check-in Friday
            (July 24) and check out Sunday (July 26).
            </p>

        <hr/>
          <div class="columns is-vcentered">
            <div class="column">

              <h2><a target="_blank"
            href="https://www.choicehotels.com/reservations/groups/ia79t3?checkInDate=2020-07-24&checkOutDate=2020-07-26&ratePlanCode=BRSDDK">Comfort
            Inn</a></h2>
            Two Queen Beds<br>
            Two miles from downtown<br>
            Hotel offers free shuttle to airport and downtown
          </p>
                      <table class="table is-bordered is-narrow">
                        <tr>
                          <th class="has-text-centered">Guests</th>
                          <th class="has-text-centered">Group rate*</th>
                        </tr>
                        <tr style="text-align:center">
                          <td class="has-text-centered">1-2</td>
                          <td class="has-text-centered">$249</td>
                        </tr>
                        <tr style="text-align:center">
                          <td class="has-text-centered">3</td>
                          <td class="has-text-centered">$259</td>
                        </tr>
                        <tr style="text-align:center">
                          <td class="has-text-centered">4</td>
                          <td class="has-text-centered">$269</td>
                        </tr>
                      </table>
                <div>*Group rate only applies to 2-night stays.</div>
                      </div>
            <div class="column">
                  <div>
                      <h2>To reserve</h2>
                      <p>Use this <a target="_blank" href="https://www.choicehotels.com/reservations/groups/ia79t3?checkInDate=2020-07-24&checkOutDate=2020-07-26&ratePlanCode=BRSDDK">link</a> or call the hotel (and mention "Bolt-Fleming Wedding")</p>
                                <p>If you want to stay additional nights or only for one night, please call and arrange over the phone. Group rates may not apply.</p>
                </div>
                  <div><br>
                      <b>Phone</b> <br>
                      (231) 778-8000
                  </div>
            </div>
        </div>
                  <div>
                      <b>Address</b> <br>
                      460 Munson Ave. US Hwy 31 <br>
                      Traverse City, MI 49686
                  </div>
            <hr/>

          <h1>Other options</h1>
          <table class="table is-hoverable">
            <tbody>
              <tr>
                <td class="has-text-centered">
                  <b>Nearby hotels</b>
                </td>
                <td class="has-text-centered">
                <p>
                        <p>Comfort Inn is conveniently located in walking distance of other hotels. We do not have a block at any of these hotels, however should you stay at any listed below, it would be a short walk to the Comfort Inn to board the shuttle service.</p>
                        <a target="_blank"
                        href="https://www.radissonhotels.com/en-us/hotels/country-inn-traverse-city-mi?cid=a:se+b:gmb+c:amer+i:local+e:cis+d:us+h:USATCMI">Country
                        Inn Suites</a> (1 min walk) <br><a target="_blank"
                        href="https://www.redlion.com/knights-inn/mi/traverse-city/knights-inn-traverse-city">Knights
                        Inn</a> (2 min walk) <br><a target="_blank"
                        href="https://www.bestwestern.com/en_US/book/hotel-rooms.23043.html?iata=00171880&ssob=BLBWI0004G&cid=BLBWI0004G:google:gmb:23043">Best
                        Western Plus</a> (5 min walk) <br><a target="_blank"
                        href="https://www.wyndhamhotels.com/hojo/traverse-city-michigan/howard-johnson-of-traverse-city/overview">Howard
                        Johnson</a> (5 min walk) <br><a target="_blank"
                        href="https://www.choicehotels.com/michigan/traverse-city/cambria-hotels/mi325?source=gyxt">Cambria
                        Suites</a> (7 min walk)</p>
                  If none of those suit you, this local
                  <a href="https://www.traversecity.com/hotels/" target="_blank"
                    >website</a
                  >
                  also has a pretty comprehensive list of the options to explore. Hotel options
                  further from Traverse City will be cheaper.
                </td>
              </tr>
              <tr>
                <td class="has-text-centered"><b>Airbnb & VRBO</b></td>
                <td class="has-text-centered">
                  We definitely recommend looking at house / room rental
                  options.
                </td>
              </tr>
              <tr>
                <td class="has-text-centered"><b>Camping</b></td>
                <td class="has-text-centered">
                  The nearest campground the
                  <a
                    href="http://www.dnr.state.mi.us/parksandtrails/details.aspx?id=501&type=SPRK"
                    target="_blank"
                    >Traverse City State Park</a
                  >, 2 1/2 miles from Front street (downtown Traverse City).
                  These campsites are $33 and the most affordable option in the
                  area. Reservations can be made 6 months in advance and they
                  book out fast so mark your calendar for end of January to get
                  a site.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <div id="schedule-img" class="bgimg-2">
      <div class="caption">
        <span class="border">SCHEDULE & THE BIG DAY</span>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns">
            <div class="column is-1">&nbsp;</div>
            <div class="column">
              <table class="table is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <td class="has-text-centered">
                      <span class="is-size-2 has-text-weight-bold">Thu</span>
                      <br />
                      <span class="is-size-4">Jul 23</span>
                    </td>
                    <td class="has-text-centered">
                      <h2>Group outing!</h2>
                      [Location & time TBD, check back later.]
                    </td>
                  </tr>

                  <tr>
                    <td class="has-text-centered">
                      <span class="is-size-2 has-text-weight-bold">Fri</span>
                      <br />
                      <span class="is-size-4">Jul 24</span>
                    </td>
                    <td class="has-text-centered">
                      <h2>Welcome Party!</h2>
                      [Specific time TBD, check back later.] We'd love for you
                      to drop by Friday afternoon at
                      <a href="javascript:;" id="sblauncher"
                        >The Little Fleet</a
                      >
                      (Traverse City’s only open-air bar with seasonal food
                      trucks). We hope you'll come say hello! Please note this
                      is a no-host, informal get-together. We ask guests to
                      cover their own meal and drinks.
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="has-text-centered
                      "
                    >
                      <span class="is-size-2 has-text-weight-bold">Sat</span>
                      <br />
                      <span class="is-size-4">Jul 25</span>
                    </td>
                    <td class="has-text-centered">
                      <h2>The Big Day!</h2>
                      Dress attire: whatever suits your fancy and let’s you get
                      dancy. <br /><br />
                      <p>
                        <span class="has-text-weight-bold">4:00</span
                        ><span class="has-text-weight-bold"
                          >&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Ceremony
                          on the lawn</span
                        >
                      </p>
                      <p>
                        <span class="has-text-weight-bold">4:30</span
                        ><span class="has-text-weight-bold">
                          &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Cocktails,
                          appetizers, & lawn games</span
                        >
                      </p>
                      <p>
                        <span class="has-text-weight-bold">6:00 - Midnight</span
                        ><span class="has-text-weight-bold"
                          >&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Dinner
                          & dancing</span
                        >
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="column is-1">&nbsp;</div>
          </div>
        </div>
      </div>
    </section>

    <div id="todo-img" class="bgimg-3">
      <div class="caption">
        <span class="border">THINGS TO DO IN TRAVERSE CITY</span>
      </div>
    </div>

    <section id="todo-section" class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <todo-card
              title="Sleeping Bear Dunes National Lakeshore"
              :links="['https://www.nps.gov/slbe/index.htm']"
              img="sleepingbear"
            >
              <p class="has-text-centered has-background-warning is-size-5">
                MUST DO!
              </p>
              <p>
                It's 40 min west of Traverse City but worth the drive. The New
                York Times described the Sleeping Bear Dunes National Lakeshore
                as "one of Midwest's most delightful surprises". Climb the
                Dunes, swim at Good Harbor Bay Beach, hike to one of the many
                breathtaking overlooks, or bike the Sleeping Bear Heritage Trail
                - a 27-mile multi-use pathway. Recommend: drive Pierce Stocking
                Scenic Drive (7 mile loop), hike Pyramid Point Trail (2.7 miles)
                or Empire Bluff Trail (1.5 miles).
              </p>
            </todo-card>
          </div>
          <div class="column">
            <todo-card
              title="Leelanau or Old Mission Peninsula Winery Trails"
              :links="[
                'https://www.lpwines.com/wineries/',
                'https://www.ompwinetrail.com/wineries/'
              ]"
              img="winery"
            >
              <p class="has-text-centered has-background-warning is-size-5">
                MUST DO!
              </p>
              <p>
                Definitely spend an afternoon on one (or both) of these small
                peninsulas which are American Viticultural Areas; Leelanau boast
                24 wineries and Old Mission 11. Shuttles/tours can also be
                booked in town. Make sure to hit 2 Lads, Brys Estate, Bonobo, 45
                North Vineyard, Shady Lane Cellars, and Suttons Bay Ciders (best
                views!). Leelanau Peninsula is also home to the delicious
                <a target="_blank" href="http://www.9beanrows.com/bakery"
                  >9 Bean Rows bakery</a
                >.
              </p>
            </todo-card>
          </div>
          <div class="column">
            <todo-card
              title="Microbreweries"
              :links="[
                'https://www.traversecity.com/food-and-drink/microbreweries/'
              ]"
              img="hoplot"
            >
              Breweries and distilleries also abound. If you are on the Leelanau
              Peninsula, make sure to hit Hop Lot. This small batch microbrewery
              has an on-site hop / beer garden, patio, firepits, and games.
              Other noteworthy mentions:
              <a target="_blank" href="https://rarebirdbrewpub.com/"
                >Rare Bird Brewpub</a
              >,
              <a target="_blank" href="http://www.tcwhiskey.com/"
                >Traverse City Whiskey Co</a
              >, and
              <a
                target="_blank"
                href="https://www.jollypumpkin.com/jp/traverse-city-brewery"
                >Jolly Pumpkin Brewing</a
              >
              (great sours) is next door to our wedding venue.
            </todo-card>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <todo-card title="Float, sail, paddle, or swim" img="kayak">
              Go
              <a target="_blank" href="http://www.nauti-cat.com/">sailing</a>
              (or
              <a href="http://tbparasail.com/" target="_blank">parasailing</a
              >!), rent
              <a target="_blank" href="https://www.sunsetwatersports.com/"
                >boats, jetskis,</a
              >
              or
              <a target="_blank" href="https://paddletc.com/">kayaks/SUP</a>, or
              <a
                href="https://crystalriveroutfitters.com/kayak-canoe-trips/"
                target="_blank"
                >canoes</a
              >. Try tubing or kayaking down the Crystal or Platte River which
              flows into Lake Michigan. For beach lounging, check out Clinch
              Park Beach in town or
              <a
                target="_blank"
                href="https://www.mlive.com/entertainment/2016/08/10_beaches_traverse_city.html"
                >these others</a
              >.
            </todo-card>
          </div>
          <div class="column">
            <todo-card title="Bike" img="biking">
              <a
                target="_blank"
                href="https://www.grandtraversebiketours.com/tour-offerings.html"
                >Rent bikes/take a tour</a
              >
              and bike
              <a
                target="_blank"
                href="https://traversetrails.org/trail/leelanau-trail/"
                >the Leelanau Trail</a
              >, a paved trail (15 miles one way) from Traverse City out to
              Leelanau Peninsula. Try the
              <a target="_blank" href="https://www.kayakbikebrew.com/"
                >Kayak, Bike & Brew</a
              >- a 4 hour tour of breweries - or a
              <a
                href="https://www.grandtraversebiketours.com/bike-and-sail.html"
                target="_blank"
                >Bike and Sail tour.</a
              >
            </todo-card>
          </div>
          <div class="column">
            <todo-card
              title="Explore downtown Traverse City (+more)"
              img="thelittlefleet"
            >
              <p>
                Downtown Traverse city, specifically Front Street, has great
                <a target="_blank" href="https://www.downtowntc.com/"
                  >dining and shopping</a
                >. Points of interest:
                <a href="https://www.patisserieamietc.com/" target="_blank"
                  >Patisserie Amie</a
                >
                (French brunch),
                <a target="_blank" href="https://folgarellis.net/menu/"
                  >Folgarelli’s Market</a
                >
                (sandwiches),
                <a
                  target="_blank"
                  href="https://www.piratescove.net/locations/michigan/traverse-city/"
                  >Pirate’s Cove Adventure Park</a
                >
                (mini-golf and more!),
                <a target="_blank" href="http://moomers.com/"
                  >Moomers Homemade Ice Cream</a
                >,
                <a target="_blank" href="https://stateandbijou.org/"
                  >Bijou by the Bay</a
                >
                a historic waterfront movie theater. The Village at Grand
                Traverse Commons has great food, art, and shops in century-old
                buildings within a giant park.
              </p>
              <p>
                20 min drive from Traverse City there’s
                <a
                  target="_blank"
                  href="https://www.grandtraverseresort.com/golf/overview"
                  >Golf</a
                >
                and
                <a
                  target="_blank"
                  href="https://www.turtlecreekcasino.com/?utm_source=gmb&utm_medium=organic&utm_campaign=local"
                  >gambling</a
                >.
              </p>
            </todo-card>
          </div>
        </div>
      </div>
    </section>
    <div id="map-header" class="bgimg-empty">
      <div class="caption">
        <span class="border">MAP</span>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div id="map">&nbsp;</div>
      </div>
    </section>
    <div id="photos-header" class="bgimg-empty">
      <div class="caption">
        <span class="border">PHOTOS</span>
      </div>
    </div>
    <section class="section">
      <div id="photo-section" class="container">
        <div class="content">
          <inline-images></inline-images>
        </div>
      </div>
    </section>
    <div id="registry-header" class="bgimg-empty">
      <div class="caption">
        <span class="border">REGISTRY</span>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <p class="is-size-2 has-text-centered">Coming soon!</p>
      </div>
    </section>
  </div>
</template>

<script>
import navbar from "./navbar";
import photos from "./photos";
import cards from "./cards";

export default { components: { navbar, photos, cards } };
</script>
