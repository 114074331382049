<template>
  <div id="rsvp">
    <section class="section">
      <div class="container">
        <p class="is-size-2 has-text-centered">RSVP</p>
        <div id="rsvp-form">
          <div class="field">
            <label class="label">Name (first and last, please)</label>
            <p class="control">
              <input
                v-model="name"
                v-bind:class="{ 'is-danger': errors.name }"
                class="input"
                type="text"
                name="name"
                placeholder="Who are you?"
              />
               <p v-if="errors.name" class="help is-danger">We need to know who you
               are!</p>
            </p>
          </div>
          <div class="field">
            <label class="label">Attending?</label>
            <div class="control">
              <label class="radio">
                <input type="radio" value="accept" v-model="rsvp" />
                Wouldn't miss it!
              </label>
              <label class="radio">
                <input type="radio" value="decline" v-model="rsvp" />
                Sadly can't make it.
              </label>
              <p v-if="errors.rsvp" class="help is-danger">We need to know if you'll be
              able to make it!</p>
            </div>
          </div>
          <div class="field">
            <label class="label">What would you like to eat?</label>
            <div class="control">
              <label class="radio">
                <input type="radio" value="chicken" v-model="food" />
                Chicken
              </label>
              <label class="radio">
                <input type="radio" value="fish" v-model="food" />
                Fish
              </label>
              <label class="radio">
                <input type="radio" value="vegetarian" v-model="food" />
                Vegetarian
              </label>
            </div>
              <p v-if="errors.food" class="help is-danger">We need to know what to feed
              you!</p>
          </div>
          <div class="field">
            <label class="label">Any allergies or dietary requirements?</label>
            <p class="control">
              <input
                class="input"
                type="text"
                v-model="diet"
                placeholder="Leave this blank if you'll be eating any and everything..."
              />
            </p>
          </div>
          <div class="field">
            <label class="label">What song(s) will get you on the dance floor?!</label>
            <div class="control">
              <textarea
                class="textarea"
                placeholder="Songs, albums, artists, or genres are all acceptable"
                v-model="songs"
              ></textarea>
            </div>
          </div>
          <div class="field">
            <p class="control">
              <button v-on:click="onSubmit" class="button is-warning">
                Submit
              </button>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { router } from "./router";

export default {
  data: function() {
    return {
    postUrl: "https://us-central1-persona-172110.cloudfunctions.net/hello_http",
    errors: {
        name: false,
        rsvp: false,
        food: false,
    },
      name: null,
      rsvp: null,
      food: null,
      diet: null,
      songs: null
    };
  },
  methods: {
  isFormValid: function() {
    this.errors.name = (this.name === undefined || this.name == null || this.name.trim() === "");
    this.errors.rsvp = (this.rsvp === undefined || this.rsvp === null);
    this.errors.food = (this.food === undefined || this.food === null);
    return Object.values(this.errors).filter(e => e).length === 0;
  },
    onSubmit: function() {
      const isValid = this.isFormValid();
      if(isValid) {
        const data = {
            name: this.name,
            rsvp: this.rsvp,
            food: this.food,
            diet: this.diet,
            songs: this.songs
        };
        router.push('/');
        fetch(this.postUrl, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        .then(resp => console.log(resp.status));
      }
    }
  }
};
</script>
