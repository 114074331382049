import Vue from "vue";
import VueRouter from "vue-router";

import App from "./App";
import Rsvp from "./Rsvp";

Vue.use(VueRouter);

export const router = new VueRouter({
  routes: [
    { path: "/", component: App },
    { path: "/rsvp", component: Rsvp }
  ]
});
