import Vue from "vue";
import { bus } from "./bus";
import { mobilecheck } from "./map";
import images from "images/winners/*.JPG";
import imagesLower from "images/winners/*.jpg";

const startIdx = Math.floor(
  Math.random() *
    Math.floor(
      Object.keys(images).length + Object.keys(imagesLower).length - 0.01
    )
);
const imageList = { ...images, ...imagesLower };

export function scrollTo(id) {
  $("html,body").animate(
    { scrollTop: $("#" + id).offset().top },
    Math.abs(window.scrollY - $("#" + id).offset().top) * 0.25
  );
}

export const navbar = Vue.component("nav-and-modal", {
  data: function() {
    return {
      burgerActive: false,
      activeSection: "",
      currentPhotoIdx: startIdx,
      currentPhoto: imageList[Object.keys(imageList)[startIdx]],
      photos: imageList,
      isActive: false
    };
  },
  methods: {
    toggleBurger: function() {
      this.burgerActive = !this.burgerActive;
    },
    scrollTo: function(location) {
      scrollTo(location);
      this.burgerActive = false;
    },
    launchModal: function() {
      this.setActive();
      this.burgerActive = false;
    },
    preload: function(idx) {
      const prevIndex =
        idx - 1 < 0 ? Object.keys(this.photos).length - 1 : idx - 1;
      const nextIndex = (idx + 1) % Object.keys(this.photos).length;

      const img = new Image();
      img.src = this.photos[Object.keys(this.photos)[prevIndex]];
      img.src = this.photos[Object.keys(this.photos)[nextIndex]];
    },
    next: function() {
      this.currentPhotoIdx =
        (this.currentPhotoIdx + 1) % Object.keys(this.photos).length;
      this.preload(this.currentPhotoIdx);
      this.currentPhoto = this.photos[
        Object.keys(this.photos)[this.currentPhotoIdx]
      ];
    },
    prev: function() {
      this.currentPhotoIdx =
        this.currentPhotoIdx - 1 < 0
          ? Object.keys(this.photos).length - 1
          : this.currentPhotoIdx - 1;
      this.preload(this.currentPhotoIdx);
      this.currentPhoto = this.photos[
        Object.keys(this.photos)[this.currentPhotoIdx]
      ];
    },
    setActive: function() {
      this.isActive = true;
    },
    setInactive: function() {
      this.isActive = false;
    }
  },
  mounted: function() {
    window.addEventListener("keyup", event => {
      if (event.keyCode === 37) {
        this.prev();
      }
      if (event.keyCode === 39) {
        this.next();
      }
      if (event.keyCode === 27) {
        this.setInactive();
      }
    });
  },
  created() {
    // Using the server bus
    bus.$on("launchModal", idx => {
      this.currentPhotoIdx = idx;
      this.currentPhoto = this.photos[Object.keys(this.photos)[idx]];
      this.isActive = true;
    });
  },
  template: `<div>
        <nav
          id="navbar"
          class="navbar is-fixed-top"
          role="navigation"
          aria-label="main navigation"
        >
          <div class="navbar-brand">
            <a
              role="button"
              class="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              v-on:click="toggleBurger"
              v-bind:class="{ 'is-active': burgerActive }"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div
            id="navbarBasicExample"
            class="navbar-menu"
            v-bind:class="{ 'is-active': burgerActive }"
          >
            <div class="navbar-start">
              <a
                class="navbar-item"
                v-on:click="scrollTo('travel-img')"
                id="travel-link"
                >Getting There</a
              >
              <a
                class="navbar-item"
                v-on:click="scrollTo('lodging-img')"
                id="lodging-link"
                >Lodging</a
              >
              <a
                class="navbar-item"
                v-on:click="scrollTo('schedule-img')"
                id="schedule-link"
                >Schedule</a
              >
              <a
                class="navbar-item"
                v-on:click="scrollTo('todo-img')"
                id="todo-link"
                >Things to do</a
              >
              <a class="navbar-item" v-on:click="scrollTo('map-header')" id="map-link"
                >Map</a
              >
              <a class="navbar-item" v-on:click="launchModal">Photos</a>
              <a class="navbar-item" v-on:click="scrollTo('registry-header')" id="registry-link">Registry</a>
            </div>
          </div>
        </nav>
        <div
          id="image-modal"
          class="modal"
          v-bind:class="{'is-active': isActive}"
          style="z-index: 1000"
        >
          <div class="modal-background"></div>
          <div class="modal-content">
            <p class="image">
              <!-- <img src="https://bulma.io/images/placeholders/1280x960.png" alt="" />
          -->
              <!-- <img src="images/winners/AmberandScottWedding-332.jpg" alt="" /> -->
              <img v-bind:src="currentPhoto" alt="" />
            </p>
            <div class="carousel-container">
              <div class="carousel-buttons">
                <span class="icon is-large" v-on:click="prev">
                  <i
                    class="oi"
                    data-glyph="arrow-circle-left"
                    aria-hidden="true"
                /></span>
                <span class="icon is-large" v-on:click="next">
                  <i
                    class="oi"
                    data-glyph="arrow-circle-right"
                    aria-hidden="true"
                /></span>
              </div>
            </div>
          </div>
          <button
            id="modal-close"
            class="modal-close is-large"
            aria-label="close"
            v-on:click="setInactive"
          ></button>
        </div>
        </div>`
});
